<template>
<div class="">
    <div class="container-fluid  py-5" style="background-color: #FFFDEE">
        <div class="container py-2 text-center">
            <div class="row">
                <div class="col-12 textevent">
                    <h1>EVENT & PROMO</h1>
                </div>
            </div>
            <div class="row">
                <div class="col-12" v-for="first in firsts" :key="first.index">
                    <div class=" bg-Card">
                    <router-link :to="{ name: 'detail_event_promo', params: { slug: first.slug}}" class="nav-link px-0 mt-0 py-0 my-0 mx-0">
                        <div class="row">
                            <div class="col-md-6">
                            <img :src="'https://adm-estusae.makutapro.id/storage/images/'+first.article_img" class="img-fluid" width="100%" height="auto" alt="...">
                            </div>
                            <div class="col-md-6 mt-5 pt-4 text-start ">
                                <h3 class="card-title judul">{{first.article_title}}</h3>
                                <p class="card-text contentEvent" v-html="first.article_content"></p>
                                <img src="../assets/images/Group 14.png" class="" alt="">
                            </div>
                        </div>
                    </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid  py-5" style="background-color: #FFFDEE">
        <div class="container py-2 text-center">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-4" v-for="article in articles" :key="article.index">
                    <div class=" bg-Card">
                        <router-link :to="{ name: 'detail_event_promo', params: { slug: article.slug}}" class="nav-link px-0 mt-0 py-0 my-0 mx-0">
                        <div class="row">
                            <div class="col-md-12">
                            <img :src="'https://adm-estusae.makutapro.id/storage/images/'+article.article_img" class="img-fluid" width="100%" height="auto" alt="...">
                            </div>
                        </div>
                        <div class="row my-3 mx-3">
                            <div class="col-md-12 text-start btn-sm">
                                <h5 class="card-title judul">{{article.article_title}}</h5>
                                <p class="card-text contentEvent" v-html="article.article_content"></p>
                                <img src="../assets/images/Group 14.png" class="pb-3 " alt="">
                            </div>
                        </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<style scoped>
.textunit {
    font-family: Manuale;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.35em;
    color: #2E2D2D;
}

.textevent{
    font-family: Manuale;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.1em;
    color: #2E2D2D;
}

.bg-Card{
    background-color: #2E2D2D;
}

.judul{
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.01em;
    color: #FFFDEE;
}

.contentEvent{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.025em;
    color: #FFFDEE;

    }
</style>

<script>

export default {
  
    name: 'event_promo',
     mounted () {
    window.scrollTo(0, 0)
  },
  data(){
            return{
                detail:[],
                articles:[],
                firsts:[],   
            }
        },
    created(){
        
        this.$axios.get('https://adm-estusae.makutapro.id/api/contentarticle')
        .then((response) => {
            this.articles = response.data.data
        })
        this.$axios.get('https://adm-estusae.makutapro.id/api/firstarticle')
        .then((response) => {
            this.firsts = response.data.data
         })
    },
}
</script>
